<template>
	<div :class="themeClass">
		<div class="margin-t-10 bg-fff" v-loading='!isData'>
			<div class="u-my-mesText font-size18 font-weight700">
				<div class="my-invoice-text">我的资料</div>
			</div>
			<div class="user-left-content">
				<div class="bgc-fff my-stuff-content flex-row">
					<el-form ref="form" :model="form" label-width="130px" :label-position="labelPosition">
						<el-form-item label="名称：">
							{{form.userNmae==form.customerName?form.userNmae:form.userNmae+'-'+form.customerName}}
						</el-form-item>
						<el-form-item label="商城账号：">
							{{ form.shopcode }}
						</el-form-item>
						<el-form-item label="会员等级：">
							<a data-v-2ed7659d="" href="#/MemberCenter" class="">{{ form.memberLever }}&nbsp;></a>
						</el-form-item>
						<el-form-item label="业务服务区域：">
							{{ form.ownarea }}
						</el-form-item>
						<el-form-item label="绑定手机号：">
							{{ form.userPhone }}
							<!-- <span class="color-theme padding-l-10 changPersonbtn">
								更改
							</span> -->
						</el-form-item>
						<div v-if="fCommerceTypeID==2">
							<el-row class=" font-size15 font-weight700 business-info">
								企业信息 <span class="margin-l-10 font-size13 font-weight400 color-theme">审核通过</span>
							</el-row>
							<div>
								<el-form-item label="商业类型：">
									{{ form1.fCommerceType }}
								</el-form-item>
								<el-form-item label="企业名称：">
									{{ form1.fCustomerName }}
								</el-form-item>
								<el-form-item label="统一信用代码：">
									{{ form1.fIdentityCode }}
								</el-form-item>
								<el-form-item label="住所：">
									{{ form1.fAddress }}
								</el-form-item>
								<el-form-item label="发票类型：">
									{{ form1.fInvoiceClassName }}
								</el-form-item>
								<el-form-item label="银行账号：">
									{{ form1.fBankAccounts }}
								</el-form-item>
								<el-form-item label="开户行：">
									{{ form1.fBankNames }}
								</el-form-item>
								<el-form-item label="公司电话：">
									{{ form1.fPhone }}
								</el-form-item>
								<!-- <el-form-item label="业务服务区域：">
									{{ form1.fGovernmenArea }}
								</el-form-item> -->
							</div>

						</div>

						<!-- 	<div class="flex-row imglistBox">
							<div class="">
								<div class="text-left font-weight700 font-size14 line-height35">
									身份证
								</div>
								<div class="mysuff-image-bgcf5f6fa"></div>
							</div>
							<div class="">
								<div class="text-left font-weight700 font-size14 line-height35">
									营业执照
								</div>
								<div class="mysuff-image-bgcf5f6fa"></div>
							</div>
							<div class="">
								<div class="text-left font-weight700 font-size14 line-height35">
									授权委托书
								</div>
								<div class="mysuff-image-bgcf5f6fa"></div>
							</div>
						</div> -->
						<!-- 图片放大 -->
						<div class="flex-row imglistBox">
							<div v-for="(item,index) in imgList" :key="index" class="margin-r-20 flex-column">
								<div class="font-weight700 font-size14 font-color-333 margin-b-10">{{item.name}}</div>
								<el-image style="width: 100px; height: 100px" :src="item.fullFPath"
									:preview-src-list="[item.fullFPath]">
								</el-image>
							</div>
						</div>
						<div style="display: flex; width: 60%;">
							<div v-if="isShowButton" class="changbtn-box text-center"
								style="border: 2px solid #0173FE; background-color: #0173FE;width: 120px;height: 40px;border-radius: 5px !important;"
								@click="changeStuff()">
								<span class="changbtn font-color-fff font-size14">变更资料</span>
							</div>
							<div v-if="fIsShow" class="changbtn-box text-center"
								style="border: 2px solid rgba(1, 115, 254, 0.2); background-color: rgba(1, 115, 254, 0.15);width: 120px;height: 40px;border-radius: 5px !important;"
								@click="addStuff()">
								<span class="changbtn font-size14" style="color: #666;">完善资料</span>
							</div>
						</div>

					</el-form>
				</div>
				<div class="user-right-top flex-row">
					<div class="top-userinfo flex-row">
						<div class="user">
							<el-avatar :size="70" :src="url"></el-avatar>
						</div>
						<div class="userinfo-right font-size13">
							<div>用户名: {{ form.userNmae }}</div>
							<div>用户类型：{{fCommerceTypeID==1?'个人用户':'企业用户'}}</div>
							<div class="changeUserImg bg-fff margin-t-4 font-size12 pointer"
								@click="centerDialogVisible = true">
								更改头像
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="上传头像" :visible.sync="centerDialogVisible" width="400px" center>
			<div class="flex-row-center-center">
				<UploadImgList :rules="{}" :uploadToken="uploadToken" class="uploadimglist" :limit="1" :accept="accept"
					v-model="imgListName" uploadType="image" />
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="determine">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import {
		mapGetters,
		mapActions
	} from "vuex";
	import imageBox from '@/components/imageBox/imageBox.vue'
	import UploadImgList from '@/components/upload/UploadImgList';
	export default {
		components: {
			imageBox,
			UploadImgList
		},
		data() {
			return {
				isData: false, //加载数据完成
				isShowButton: true, //是否显示按钮
				isUnderReview: false, //是否审核中
				fEmail: '',
				fit: "cover",
				url: require("@/assets/personal.png"),
				// url: "//img14.360buyimg.com/n7/jfs/t1/60319/15/6105/406802/5d43f68aE9f00db8c/0affb7ac46c345e2.jpg",
				labelPosition: "right",
				form: {
					userNmae: "",
					shopcode: "",
					memberLever: "",
					ownarea: "",
					userPhone: "",
					customerName: '',
					fPhone: '',
				},
				flag: false,
				form1: {}, //企业信息
				fCommerceTypeID: null, //商业类型
				imgList: [], //显示图片

				imgData: [], //图片数据
				fVerifiedStatus: false, //是否认证成功
				action: '',
				uploadToken: {},
				accept: '.jpg,.jpeg,.png,.JPG,.JPEG,.PBG',
				centerDialogVisible: false,
				imgListName: [],
				isEmail : true,
				fIsShow : true
			};
		},
		computed: {
			...mapGetters(["getThemeName", 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			this.getUserData();
			this.getUpLoadUrl();
			this.getUploadToken();
			this.getLegalEntity();
		},
		methods: {
			//获取上传路径
			getUpLoadUrl() {
				this.ApiRequestPostNOMess("/api/mall/ebbase/para-value/get-config").then(
					(result) => {
						this.action = result.obj.annexServerUrl + 'upload';
					},
					(rej) => {}
				);
			},
			getUploadToken() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/baseinfo/get-avatar-upload-token').then(
					(result) => {
						this.uploadToken = {
							token: result.obj.token
						}
					},
					(rej) => {}
				);
			},
			// 确认更换
			determine() {
				console.log(this.imgListName);
				this.ApiRequestPostNOMess('api/mall/sys/account/set-avatar', {
					fileName: this.imgListName[0].fPath
				}).then(
					result => {
						this.getUserData(true)
						this.centerDialogVisible = false;
						this.imgListName = []
					},
					rej => {}
				);
			},
			//获取用户基本资料
			getUserData(e) {
				this.ApiRequestPostNOMess('/api/mall/ebcustomer/baseinfo/get-current-customer-user-info').then(
					result => {
						console.log(result, "获取用户基本资料");
						if (e) {
							this.$store.commit("setUserInfo", result.obj);
							sessionStorage.setItem("setUserInfo", JSON.stringify(result.obj));
							this.url = this.getUserInfo.fFileName;
						}
						if (result.obj.fFileName) {
							this.url = result.obj.fFileName;
						}
						this.fCommerceTypeID = result.obj.fCommerceTypeID //商业类型ID
						this.fVerifiedStatus = result.obj.fVerifiedStatus //是否认证成功
						if (this.fCommerceTypeID == 2 && !this.fVerifiedStatus) { //企业变更资料状态为审核中
							this.isData = true
							this.goToUnderReview();
							return
						} else if (this.fCommerceTypeID == 0) { //未完善或审核中
							this.getUserCurrentBill();
						} else { //认证成功
							this.form.userNmae = result.obj.fUserName //姓名
							this.form.customerName = result.obj.customerName;
							console.log(this.form);
							this.form.shopcode = result.obj.loginName //商城账号
							this.form.memberLever = result.obj.gradeName //会员等级
							if (result.obj.fFullArea != '' && result.obj.fFullArea != null && result.obj.fFullArea !=
								undefined) {
								this.form.ownarea = result.obj.fFullArea.replace(/[\\]/g, ''); //所在区域
							}
							this.form.userPhone = result.obj.phone //绑定手机号 
							//我的发票收件地址要显示个人认证信息
							this.form.fFullIDPath = result.obj.fFullIDPath //行政区域id
							localStorage.setItem("userAddressInform", JSON.stringify(this.form))
							if (this.fCommerceTypeID == 1) {
								this.isShowButton = false;
							}
							this.customerBaseInfoFull();
						}
					},
					rej => {
						this.isData = true
					}
				);
			},
			//获取用户完善资料最后一条提交单
			getUserCurrentBill() {
				this.ApiRequestPostNOMess('/api/mall/ebcustomer/adjust-bill/get-current-bill').then(
					result => {
						console.log(result, "获取用户完善资料最后一条提交单");
						this.isData = true
						if (result.obj) {
							if (result.obj.fApproveStatus == 1) { //企业认证审核中
								this.goToUnderReview();
							} else { //个人或企业仅保存
								this.changeStuff();
							}
						} else { //没有认证信息
							this.changeStuff();
						}
					},
					rej => {
						this.isData = true
					}
				);
			},
			
			//获取是否有法人
			getLegalEntity() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-curret-isshow').then(
					res => {
						if(res.obj.fIsShow == 0){
							this.fIsShow = false
						}else{
							this.fIsShow = true
						}
					}
				);
			},
			//获取用户显示资料
			customerBaseInfoFull() {
				this.ApiRequestPostNOMess('/api/mall/ebcustomer/baseinfo/get-by-current-user-customer-base-info-full')
					.then(
						result => {
							this.isData = true
							if (result.obj) {
								this.imgList = result.obj.files
								this.form1.fCommerceType = result.obj.fCommerceType
								this.form1.fCustomerName = result.obj.fCustomerName
								this.form1.fIdentityCode = result.obj.fIdentityCode
								this.form1.fGovernmenArea = result.obj.fGovernmenArea.replace(/[\\]/g, ''); //所在区域
								this.form1.fAddress = result.obj.fAddress
								this.form1.fBankNames = result.obj.fBankNames
								this.form1.fBankAccounts = result.obj.fBankAccounts
								this.form1.fInvoiceClassName = result.obj.fInvoiceClassName
								this.form1.fPhone = result.obj.fPhone
								console.log(result.obj.fEmail)
								this.fEmail = result.obj.fEmail
							}
						},
						rej => {}
					);
			},
			//跳转完善信息页面
			changeStuff() {
				this.$router.push("/PersonalContent/Enterprise");
			},
			//跳转完善资料页面
			addStuff() {
				if (this.flag) {
					this.$router.push({
						path: "/UnderReview",
					});
				} else {
					this.$router.push("/PersonalContent/Improve");
				}

			},
			//跳转审核中页面
			goToUnderReview() {
				console.log('跳转审核中');
				// this.$router.push("/about/Allperfect");
				this.$router.replace({
					path: "/UnderReview",
				});
			},

		}
	};
</script>
<style lang="scss" scoped>
	/deep/.el-icon-circle-close:before {
		color: #ffffff !important;
	}
	.u-my-mesText {
		padding-left: 13px;
	}

	.business-info {
		margin: 26px auto 8px auto;
	}

	.my-invoice-text {
		line-height: 42px;
	}

	.user-left-content {
		width: 917px;
		display: flex;
		justify-content: space-between;
		align-items: top;
	}

	.line-height35 {
		line-height: 38px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.my-stuff-content {
		width: 560px;
		padding: 0px 0 20px 13px;
	}

	::v-deep .el-form-item {
		margin-bottom: 0;
		/* .el-form-item__label, */
	}

	::v-deep .el-form-item__label,
	::v-deep .el-form-item__content {
		line-height: 33px;
	}

	.imglistBox {
		width: 100%;
		margin: 10px 10px;
	}

	.mysuff-image-bgcf5f6fa {
		width: 110px;
		height: 80px;
		background: #f5f6fa;
		margin: 0 15px 0 0;
	}

	.changbtn-box {
		margin: 50px auto 50px 10px;
		width: 235px;

		@include themify($themes) {
			background: themed("themes_color");
		}

		.changbtn {
			line-height: 40px;
		}
	}

	.changPersonbtn:hover,
	.changbtn-box:hover {
		cursor: pointer;
	}

	.user-right-top {
		width: 300px;
		min-height: 93px;
		margin: 15px 15px auto auto;
		background: #f8f9fe;
		border: 1px solid #E4E4E4;
	}

	.top-userinfo {
		margin: 12px 10px 14px 12px;
	}

	.user {
		width: 66px;
		height: 66px;
		border: 2px solid #ffffff;
		border-radius: 50%;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
		overflow: hidden;
	}

	.userinfo-right {
		padding-left: 10px;
		line-height: 23px;
		max-width: 200px;
	}

	.changeUserImg {
		width: 75px;
		line-height: 22px;
		text-align: center;
		border-radius: 2px;
		user-select: none;

		@include themify($themes) {
			color: themed("themes_color");
			border: 1px solid themed("themes_color");
		}
	}
</style>
